import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useImageSearch } from '../../../utils/useImageSearch';
import Button from '../../Button/Button';
import styles from './ImageSideBar.module.scss';

const ImageSideBar = () => {
  const { imageSearch, handleTriggerUploadImage } = useImageSearch();

  return (
    <div className={styles.root}>
      <img src={imageSearch.url} className={styles.imageBackground} />
      <p className={styles.title}>
        <FormattedMessage id="search.image.look" defaultMessage="Looks like it is:" />
      </p>
      <img src={imageSearch.url} className={styles.imageSearch} />
      <Button classList={{ root: styles.action }} onClick={() => handleTriggerUploadImage(true)}>
        <FormattedMessage id="search.image.uploadOtherImage" defaultMessage="Upload anther image" />
      </Button>
    </div>
  );
};

export default ImageSideBar;
