import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const HeadingH1 = ({ classList, term, page, complete }) => {
  if (complete) {
    return (
      <h1 className={classList} id="readyForCrawler">
        {term}
      </h1>
    );
  }
  if (term === 'korting') {
    return (
      <h1 className={classList} id="readyForCrawler">
        {page > 1 && <FormattedHTMLMessage defaultMessage="Page {page } - " id="search.pagePrefix" values={{ page }} />}
        <FormattedMessage defaultMessage="Discounts" id="search.discounts" />
      </h1>
    );
  }
  return (
    <h1 className={classList} id="readyForCrawler">
      {page === 1 ? (
        <FormattedMessage defaultMessage="Buy" id="common.lookingFor">
          {(searchPrefix) => (
            <FormattedHTMLMessage
              defaultMessage="{regular} <b>{bold}</b>"
              id="search.header.buy"
              values={{ regular: searchPrefix, bold: term }}
            />
          )}
        </FormattedMessage>
      ) : (
        <FormattedHTMLMessage
          defaultMessage="Page {page} - Results for {searchTerm} ♥ {webShopName}"
          id="search.headings.desc"
          values={{ page, searchTerm: term }}
        />
      )}
    </h1>
  );
};

export default HeadingH1;
