import React from 'react';
import styles from './SearchRedirectMessage.module.scss';
import classNames from 'classnames';
import { FormattedHTMLMessage } from 'react-intl';

const SearchRedirectMessage = ({ className, from, to }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <FormattedHTMLMessage
        id="search.searchRedirectMessage"
        values={{ query: from, suggestion: to }}
        defaultMessage="Sorry, we can not find anything for <b>'{query}'</b>. Showing results for <b>'{suggestion}'</b> instead.'"
      />
    </div>
  );
};

export default SearchRedirectMessage;
